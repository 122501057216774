const localGermanMessages = {
	languages: {
		de: "Deutsch",
		bg: "Bulgarisch",
		cs: "Tschechisch",
		da: "Dänisch",
		de_AT: "German-Austria-DE",
		de_CH: "German-Switzerland-DE",
		de_LU: "German-Luxemburg-DE",
		el: "Griechisch",
		el_CY: "Greek-Cyprus-DE",
		en: "Englisch",
		en_CY: "en_CY????-DE",
		en_IE: "English-Ireland-DE",
		en_MT: "en_MT???-DE",
		en_US: "English-USA-DE",
		es: "Spanisch",
		et: "Estnisch",
		fi: "Finnisch",
		fr: "Französisch",
		fr_BE: "French-Belgium-DE",
		fr_CH: "French-Switzerland-DE",
		fr_LU: "French-Luxemburg-DE",
		hr: "Kroatisch",
		hu: "Ungarisch",
		it: "Italienisch",
		it_CH: "Italian-Switzerland",
		lt: "Litauisch",
		lv: "Lettisch",
		mt: "Maltesisch",
		nl: "Holländisch",
		nl_BE: "Holländisch-Belgisch",
		pl: "Polnisch",
		pt: "Portugiesisch",
		ro: "Rumänisch",
		sk: "Slovakisch",
		sl: "Slowenisch",
		sr: "Serbisch",
		sv: "Schwedisch",
		tr: "Türkisch",
	},
	login: {
		signInTitle: 'Login via SSO',
		signInBtn: 'Login',
		errorRights: 'Du hast die benötigten Siam Rollen nicht. Bitte frage sie an',
		errorLogin: 'Der Login war nicht erfolgreich. Bitte kontaktiere deinen Administrator.',
	},
	save: 'Speichern',
	create: 'Erstellen',
	edit: 'Bearbeiten',
	finish: 'Fertig',
	next: 'Nächster Schritt',
	showHistory: 'Show History DE',
	escalationCreatedSuccess: "Die Eskalation wurde erfolgreich erstellt. Die automatischen Übersetzungen wird im Hintergrund generiert und benötigt mehrere Minuten.",
	escalationCreatedError: "Ein Fehler ist bei der Erstellung der Eskalation aufgetreten:  %{error}",
	escalationApproveError: "Error approving escalation. Active declines for this escalation: %{declines}",
	escalationApprove: "Escalation approved",
	unauthorized: "You are not authorized to perform this action",
	approveButton: "Approve review",
	declineButton: "Decline review",
	resources: {
		menu: {
			'general_title': 'Allgemein',
			'countries': 'Länder',
			'divisions': 'Sparten',
			'businessImpactManagers': 'Business Impact Manager',

			'escalations_title': 'Eskalationen',
			'categories': 'Kategorien',
			'escalations': 'Eskalationen',
			'escalationDrafts': 'Eskalationsentwürfe',
			'hiddenEscalations': 'Hidden Entry',

			'serviceteams_title': 'Service Teams',
			'serviceTeams': 'Service Teams',
			'teamCategories': 'Team Kategorien',

			'duties_title': 'Schichten',
			'users': 'Eskalationsmanager',
			'duties': 'Schichten',

			'history_title': 'Historie',
			'changeHistory': 'Change History DE',
			'reviewDashboard': 'Review Dashboard',

			'declines': 'Declines'
		},
		countries: {
			name: 'Länder',
			fields: {
				nameEn: 'Name EN',
				nameDe: 'Name DE',
				abbreviationEn: 'Abkürzung EN',
				abbreviationDe: 'Abkürzung DE'
			},
			filter: {
				nameEn: 'Name EN',
				nameDe: 'Name DE',
				abbreviationEn: 'Abkürzung EN',
				abbreviationDe: 'Abkürzung DE'
			}
		},
		divisions: {
			name: 'Sparten',
			fields: {
				name: 'Name',
				abbreviation: 'Abkürzung'
			},
			filter: {
				name: 'Name',
				abbreviation: 'Abkürzung'
			}
		},
		businessImpactManagers: {
			name: 'Business Impact Manager',
			general: 'Allgemein',
			phone: 'Telefon',
			fields: {
				description: 'Beschreibung',
				countryId: 'Land',
				phoneNumber: 'Telefonnummer',
				phoneNumberType: 'Telefon Typ',
				divisionId: 'Sparte'
			},
			filter: {
				description: 'Beschreibung',
				phoneNumber: 'Telefonnummer',
				phoneNumberType: 'Telefon Typ'
			}
		},
		categories: {
			name: 'Kategorien',
			fields: {
				nameEn: 'Name EN',
				nameDe: 'Name DE'
			},
			filter: {
				nameEn: 'Name EN',
				nameDe: 'Name DE'
			}
		},
		escalations: {
			solution: "Zuständige Solution oder Application",
			name: 'Eskalationen',
			draftNeedsSolutionApproval: "Dieser Entwurf muss noch von einem Vertreter der Solution genehmigt werden.",
			draftNeedsCCApproval: "Dieser Entwurf muss noch vom Control Center genehmigt werden.",
			draftNeedsBothApproval: "Dieser Entwurf muss noch sowohl vom Control Center als auch von einem Vertreter der Solution genehmigt werden.",
			draftNeedsEitherApproval: "Dieser Entwurf muss entweder noch vom Control Center oder einem Vertreter der Solution genehmigt werden.",
			countrySelection: 'Länder',
			divisionsSelection: 'Sparte',
			processSystemApplication: 'Wählen Sie ein/e passende/n Prozess / System / Applikation für die Eskalation',
			isDraft: "Entwurf",
			majorTrigger: "Major-Trigger",
			englishMajorTrigger: "Major-Trigger auf englischer Sprache",
			descriptionShort: "Fallbeschreibung",
			description: "Beschreibung der Eskalation",
			englishDescriptionEscalation: 'Englische Beschreibung für die Eskalation',
			affectedCi: 'Betroffenes CI',
			impact: "Beschreibung der Auswirkung",
			impactEn: 'Englische Beschreibung der Auswirkung',
			businessCriticalTime: 'Geschäftskritische Zeiten',
			businessCriticalDays: 'Geschäftskritische Tage',
			primaryContactId: 'Primärkontakt (wenn das gewünschte Service Team nicht gefunden werden kann, bitte Control Center kontaktieren)',
			secondaryContactsIds: 'Sekundärkontakt',
			language: "Sprache, in welcher die benutzergenerierten Felder angezeigt werden sollen (Beschreibung, Auswirkung)",
			businessImpactManagersIds: 'Business Impact Manager',
			responsiblePerson: 'Verantwortliche Person',
			responsibleGroup: 'Verantwortliche Gruppe',
			mailSubjectDe: 'Betreff',
			mailSubjectEn: 'Betreff auf Englisch',
			mailTextDe: 'Mailtext',
			mailTextEn: 'Mailtext auf Englisch',
			smsTextDe: 'SMS-Text',
			smsTextEn: 'SMS-Text auf English',
			linkResources: 'Link Resource',
			confirmMsg: 'Fertig.',
			steps: {
				countryDivisions: 'Länder und Sparten',
				processSystemApplication: 'Prozess, System, Applikation',
				description: 'Fallbeschreibung',
				impact: 'Business Impact',
				businessCriticalTime: 'Geschäftskritische Zeiten',
				contacts: 'Kontakte',
				linkResources: 'Link Resources (optional)',
				mailTemplate: 'Mail template (optional)',
				sMSTemplate: 'SMS template (optional)'
			},
			fields: {
				countryId: 'Land',
				categoryId: 'Kategorie',
				solution: "Solution",
				solutionDisplayId: "Solution Display ID",
				description: 'Beschreibung',
				impact: 'Auswirkung',
				primaryContactId: 'Primärkontakt',
				secondaryContactsIds: 'Sekundärkontakt',
				majorTrigger: "Major Trigger",
				serviceTeams: 'Service Teams',
				businessImpactManagersIds: 'Business Impact Managers',
				divisionsIds: 'Sparten',
				secondaryContacts: 'Sekundärkontakt',
				_id: "Id",
				lastUpdater: "Last Updated By",
				lastUpdatedAt: "Last Update At",
				draftApprover: "Draft Approver",
				draftApprovedAt: "Draft Approved Date",
				reviewApprover: "Review Approver",
				reviewApprovedAt: "Review Approved Date",
				furtherContacts: "Further Contacts"
			},
			filter: {
				countryId: 'Land',
				categoryId: 'Kategorie',
				majorTrigger: "Major-Trigger",
				description: 'Beschreibung',
				impact: 'Auswirkung',
				solution: "Solution",
				primaryContactId: 'Primärkontakt',
				secondaryContactsIds: 'Sekundärkontakt',
				serviceTeams: 'Service Teams',
				businessImpactManagersIds: 'Business Impact Manager',
				divisions: 'Sparten'
			}
		},
		escalationDrafts: {
			name: 'Eskalationsentwürfe',
			fields: {
				countryId: 'Land',
				categoryId: 'Kategorie',
				solution: "Solution",
				solutionDisplayId: "Solution Display ID",
				description: 'Beschreibung',
				impact: 'Auswirkung',
				primaryContactId: 'Primärkontakt',
				secondaryContactsIds: 'Sekundärkontakt',
				serviceTeams: 'Service Teams',
				businessImpactManagersIds: 'Business Impact Managers',
				divisionsIds: 'Sparten',
				secondaryContacts: 'Sekundärkontakt',
				_id: "Id",
				lastUpdater: "Last Updated By",
				lastUpdatedAt: "Last Update At",
				draftApprover: "Draft Approver",
				draftApprovedAt: "Draft Approved Date",
				reviewApprover: "Review Approver",
				reviewApprovedAt: "Review Approved Date",
				furtherContacts: "Further Contacts"
			}
		},
		serviceTeams: {
			name: 'Service Teams',
			fields: {
				it4youId: 'IT4YOU ID',
				it4youTeam: 'IT4YOU Team',
				categoryId: 'Kategorie',
				availabilities: 'Verfügbarkeiten',
				remarkEn: 'Anmerkung EN',
				remarkDe: 'Anmerkung DE',
				phoneNumber: 'Telefonnummer',
				phoneNumberType: 'Typ',
				nationalHolidays: 'Einschließlich nationaler Feiertage'
			},
			filter: {
				it4youId: 'IT4YOU ids',
				it4youTeam: 'IT4YOU Team',
				remarkEn: 'Anmerkung EN',
				remarkDe: 'Anmerkung DE',
				categoryId: 'Kategorie',
				availabilities: 'Verfügbarkeiten'
			},
			form: {
				tabGeneral: 'Allgemein',
				tabAvailabilities: 'Verfügbarkeiten',
				clockTime: 'Uhrzeit',
				fromTime: 'Von (Zeit)',
				toTime: 'bis (Zeit)',
				dateTime: 'Wochentag',
				fromDate: 'Von (Wochentag)',
				toDate: 'bis (Wochentag)',
				phone: 'Telefon'
			}
		},
		teamCategories: {
			name: 'Team Kategorien',
			fields: {
				name: 'Name',
				timeZone: 'Zeitzone'
			},
			filter: {
				name: 'Name',
				timeZone: 'Zeitzone'
			}
		},
		users: {
			name: 'Eskalationsmanager',
			fields: {
				firstName: 'Vorname',
				lastName: 'Nachname',
				emailAddress: 'E-Mail Addresse',
				isManager: 'Eskalationsmanager'
			},
			filter: {
				firstName: 'Vorname',
				lastName: 'Nachname',
				emailAddress: 'E-Mail Addresse',
				isManager: 'Eskalationsmanager'
			}
		},
		duties: {
			name: 'Schichten',
			fields: {
				managerId: 'Manager',
				from: 'Von',
				to: 'Bis',
				divisionId: 'Sparte'
			},
			filter: {
				managerId: 'Manager',
				from: 'Von',
				to: 'Bis',
				divisionId: 'Sparte'
			}
		},
		changeHistory: {
			name: 'Change Historie',
			fields: {
				entityType: 'Table',
				docId: 'ID',
				changedFields: 'Fields',
				method: 'Process',
				user: 'Person',
				createdAt: 'Date, Time'
			},
			filter: {
				entityType: 'Table',
				docId: 'ID',
				changedFields: 'Fields',
				method: 'Process',
				user: 'Person',
				createdAt: 'Date, Time'
			}
		}
	},
	emailSettings: {
		heading: "Email Präferenzen",
		explanation: "Bei verschiedenen Events wird das Esca Tool dich via E-Mail benachrichtigen. Hier kannst du einzelne Events deselektieren, um keine E-Mail-Benachrichtigung auszulösen:",
		escaApproveCreateByCCAdminToApprover: "du hast die Erstellung einer Eskalation genehmigt",
		escaApproveCreateByCCAdminToCCAdmins: "ein anderer Control Center Admin hat die Erstellung einer Eskalation genehmigt",
		escaApproveCreateByCCAdminToCreator: "ein Control Center Admin hat die Erstellung deiner Eskalation genehmigt",
		escaApproveCreateByCCAdminToSolutionAdmins: "ein Control Center Admin hat die Erstellung einer Eskalation für deine Solution genehmigt",
		escaApproveCreateBySolutionAdminToSolutionAdmins: "another solution admin from your solution team approved the creation of an escalation for your common solution",
		escaApproveCreateBySolutionAdminToCreator: "the creation of your escalation has been approved by a solution admin",
		escaApproveCreateBySolutionAdminToCCAdmins: "the creation of an escalation has been approved by a solution admin (for control center admins)",
		escaApproveCreateBySolutionAdminToApprover: "you approved the creation of an escalation (as a solution admin)",
		escaApproveModificationByCCAdminToSolutionAdmins: "a control center admin approved a modification to an escalation for your solution",
		escaApproveModificationByCCAdminToEditor: "a control center admin approved your modification to an escalation",
		escaApproveModificationByCCAdminToCreator: "a control center admin approved a modification to your escalation",
		escaApproveModificationByCCAdminToCCAdmins: "another control center admin approved a modification to an escalation",
		escaApproveModificationByCCAdminToApprover: "you approved a modification of an escalation (as a control center admin)",
		escaApproveModificationBySolutionAdminToEditor: "a solution admin approved your modification to an escalation",
		escaApproveModificationBySolutionAdminToCreator: "a solution admin approved a modification to your escalation",
		escaApproveModificationBySolutionAdminToCCAdmins: "a solution admin approved a modification to an escalation (for control center admins)",
		escaApproveModificationBySolutionAdminToApprover: "you approved a modification of an escalation for your solution",
		escaApproveModificationBySolutionAdminToSolutionAdmins: "another solution admin approved a modification to an escalation for your common solution",
		escaCreatedBySolutionAdminToCreator: "you created an escalation for your solution (as a solution admin)",
		escaCreatedBySolutionAdminToCCAdmins: "a solution admin created an escalation",
		escaCreatedByCCAdminToSolutionAdmins: "a control center admin created an escalation for your solution",
		escaCreatedByCCAdminToCreator: "you created an escalation (as a control center admin)",
		escaCreatedByCCAdminToCCAdmins: "another control center admin created an escalation",
		escaCreatedBySolutionAdminToSolutionAdmins: "another solution admin created an escalation for your common solution",
		draftCreatedByUserToSolutionAdmins: "a user created an escalation draft for your solution",
		draftCreatedByUserToCreator: "you created an escalation draft",
		draftCreatedByUserToCCAdmins: "a user created an escalation draft (for control center admins)",
		escaDeclineCreateByCCAdminToSolutionAdmins: "the creation of an escalation for your solution has been declined by a control center admin",
		escaDeclineCreateByCCAdminToCreator: "the creation of your escalation has been declined by a control center admin",
		escaDeclineCreateByCCAdminToCCAdmins: "the creation of an escalation has been declined by another control center admin",
		escaDeclineCreateByCCAdminToApprover: "you declined the creation of an escalation (as a control center admin)",
		escaDeclineCreateBySolutionAdminToSolutionAdmins: "the creation of an escalation has been declined by another solution admin",
		escaDeclineCreateBySolutionAdminToCreator: "the creation of your escalation has been declined by a solution admin",
		escaDeclineCreateBySolutionAdminToCCAdmins: "the creation of an escalation has been declined by a solution admin",
		escaDeclineCreateBySolutionAdminToApprover: "you declined the creation of an escalation (as a solution admin)",
		escaDeclineModificationByCCAdminToSolutionAdmins: "a modification of an escalation for your solution has been declined by a control center admin",
		escaDeclineModificationByCCAdminToEditor: "your modification of an escalation has been declined by a control center admin",
		escaDeclineModificationByCCAdminToCreator: "a modification of your escalation has been declined by a control center admin",
		escaDeclineModificationByCCAdminToCCAdmins: "a modification of an escalation has been declined by another control center admin",
		escaDeclineModificationByCCAdminToApprover: "you declined a modification of an escalation (as a control center admin)",
		escaDeclineModificationBySolutionAdminToSolutionAdmins: "a modification of an escalation has been declined by another solution admin",
		escaDeclineModificationBySolutionAdminToEditor: "your modification of an escalation has been declined by a solution admin",
		escaDeclineModificationBySolutionAdminToCreator: "a modification of your escalation has been declined by a solution admin",
		escaDeclineModificationBySolutionAdminToCCAdmins: "a modification of an escalation has been declined by a solution admin (for control center admins)",
		escaDeclineModificationBySolutionAdminToApprover: "you declined a modification of an escalation (as a solution admin)",
		escaModifiedByCCAdminToSolutionAdmins: "an escalation for your solution has been modified by a control center admin",
		escaModifiedByCCAdminToEditor: "you modified a solution (as a control center admin)",
		escaModifiedByCCAdminToCreator: "your escalation has been modified by a control center admin",
		escaModifiedByCCAdminToCCAdmins: "an escalation has been modified by another control center admin (for control center admins)",
		escaModifiedBySolutionAdminToSolutionAdmins: "an escalation for your solution has been modified by another solution admin",
		escaModifiedBySolutionAdminToEditor: "you modified a solution (as a solution admin)",
		escaModifiedBySolutionAdminToCreator: "your escalation has been modified by a solution admin",
		escaModifiedBySolutionAdminToCCAdmins: "an escalation has been modified by a solution admin (for control center admins)",
		escaModifyRequestByUserToSolutionAdmins: "a user requested a modification of an escalation for your solution",
		escaModifyRequestByUserToEditors: "you requested a modification of an escalation",
		escaModifyRequestByUserToCCAdmins: "a user requested a modification of an escalation (for control center admins)",
		save: "Speichern"
	},
	reviewDashboard: {
		pageTitle: "Review Dashboard",
		accordionFirstTabTitle: "Zeigen Sie Eskalationen außerhalb der Schonfrist und ausgeblendet an",
		accordionSecondTabTitle: "Zeigen Sie Eskalationen an, die überprüft werden müssen und sich in der Nachfrist befinden",
		accordionThirdTabTitle: "Geprüfte Eskalationen anzeigen",
		dashboardChartGreenColumnText: "Überprüft",
		dashboardChartYellowColumnText: "In Überprüfung / Gnadenfrist",
		dashboardChartRedColumnText: "Außerhalb der Nachfrist / Nicht überprüft und ausgeblendet",
	}
}

export default localGermanMessages;
